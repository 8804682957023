import React from "react"

const SignatureStyle = {
    borderSpacing: '20px'
}
const ImageWrapper = {
    width: '250px'
}
const ImageWrapper2 = {
    height: '50px',
    display: 'inline-block',
    marginTop: '15px'
}
const Image = {
    width: '100%'
}
const TextWrapper = {
    marginLeft: '20px',
    fontFamily: 'Arial',
    lineHeight: '1.45'
}
const Name = {
    margin: '0',
    fontWeight: 'bold',
    fontSize: '1.7em'
}
const Job =  {
    margin: '0px 0 10px 0',
    fontSize: '14px',
    color: '#e30210',
    fontWeight: '400',
    fontStyle: 'italic'
}
const Link = {
    textDecoration: 'none',
    color: 'black'
}
const Link2 = {
    color: '#4870df'
}
const Link3 = {
    color: 'black'
}
const SmallText = {
    fontSize: '16px',
    margin: '10px 0'
}
const Socials = {
    // 
}
const Social = {
    // 
}
const SocialImg = {
    height: '25px',
}

function telWithoutSpaces (number) {
    return number.replace(/\s/g, '');
}

const Signature = ({ pageContext }) => (
    <table className="signature" style={SignatureStyle}>
        <tbody>
            <tr>
                <td className="image__wrapper" style={ImageWrapper}>
                    <img
                        src="https://files.myclientisrich.com/dehn-mails/signature.png"
                        alt={pageContext.signatureData.name}
                        style={Image}
                        width="250"
                        height="250"
                    />
                </td>
                <td className="text__wrapper" style={TextWrapper}>
                    <h2 style={Object.assign({}, Name, {color: pageContext.mainColor})}>{pageContext.signatureData.name}</h2>
                    <h3 style={Object.assign({}, Job)}>{pageContext.signatureData.job}</h3>
                    <p style={SmallText}>
                        <a href={`tel:${telWithoutSpaces(pageContext.signatureData.tel.fixe)}`} style={Link}>T. {pageContext.signatureData.tel.fixe}</a><br/>
                        {pageContext.signatureData.tel.port ? (
                            <>
                                <a  href={`tel:${telWithoutSpaces(pageContext.signatureData.tel.port)}`} style={Link}>P. {pageContext.signatureData.tel.port}</a><br/>
                            </>
                        ) : null }
                    </p>
                    <p style={SmallText}>
                        Dehn France SARL<br/>
                        4, rue de Lisbonne, Bat 2, 67300 Schiltigheim<br/>
                        <a href="https://www.dehn.fr" style={Link}>www.dehn.fr</a>
                    </p>
                    <p style={SmallText}>
                        <a href="https://www.dehn.fr" style={Link2}>S'inscrire à notre Newsletter</a>
                    </p>
                    <div style={Socials}>
                        <a href="https://www.linkedin.com/company/dehn-france?originalSubdomain=fr" style={Social}>
                            <img src="https://files.myclientisrich.com/dehn-mails/linkedin.png" alt="Logo LinkedIn" style={SocialImg} width="40" height="25" />
                        </a>
                        <a href="https://www.facebook.com/DEHNfr" style={Social}>
                            <img src="https://files.myclientisrich.com/dehn-mails/facebook-logo.png" alt="Logo Facebook" style={SocialImg} width="27" height="25" />
                        </a>
                        <a href="https://www.youtube.com/user/DEHNFRANCE" style={Social}>
                            <img src="https://files.myclientisrich.com/dehn-mails/youtube.png" alt="Logo YouTube" style={SocialImg} width="34" height="25" />
                        </a>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="image__wrapper" style={ImageWrapper}>
                    <img
                        src="https://files.myclientisrich.com/dehn-mails/dehn-app.png"
                        alt="Application Dehn"
                        style={Image}
                        width="250"
                        height="285"
                    />
                </td>
                <td className="text__wrapper" style={TextWrapper}>
                    <h2 style={Object.assign({}, Name, {color: pageContext.mainColor})}>Découvrir l'application Dehn</h2>
                    <div>
                        <div className="image__wrapper">
                            <a href="https://apps.apple.com/fr/app/dehn/id1513329208" style={ImageWrapper2}>
                                <img
                                    src="https://files.myclientisrich.com/dehn-mails/app-store.png"
                                    alt="Application Dehn App Store"
                                    width="174"
                                    height="50"
                                />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.dehn.app" style={ImageWrapper2}>
                                <img
                                    src="https://files.myclientisrich.com/dehn-mails/google-play.png"
                                    alt="Application Dehn Google Play"
                                    width="169"
                                    height="50"
                                />
                            </a>
                        </div>
                    </div>
                    <p style={SmallText}>
                        Ou sur <a href="https://www.dehn-app.fr" style={Link3}>www.dehn-app.fr</a>
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
)


export default Signature
